import { Invoice } from "@/types"
import { INDIVIDUAL_ITEMS } from "../builder/utils"
import { getInvoiceTotals } from "@/utils/calculations"

export const adaptOutgoingInvoice = (invoice: Invoice) => {
  const {
    customers,
    items,
    /* eslint-disable */
    account,
    is_recurring,
    use_statement_period,
    is_recurring_modifiable,
    is_series_modifiable,
    has_changed,
    has_sync_data_changed,
    should_sync_data,
    sync_mode,
    allowable_actions,
    is_readonly,
    /* eslint-enable */

    id,
    account_pricemodifier_id,
    pricemodifier_name,
    pricemodifier_value_flat,
    pricemodifier_value_pct,
    statement_period_start,
    statement_period_end,
    expected_issue_at,
    expected_notify_at,
    due_at,
    recipient_message,
    internal_note,
    is_send_via_email,
    is_send_via_sms,
    card_surcharge_to,
    email_cc_recipients = [],

    is_recipient_message_recurring, // shouldn't take effect for invoices that has been issued
    is_pricemodifier_recurring, // shouldn't take effect for invoices that has been issued
  } = invoice

  const { subTotal } = getInvoiceTotals(invoice)

  let series = null

  if (invoice.series) {
    // @ts-ignore
    series = {
      id: invoice.series.id,
      frequency: invoice.series.frequency,
      frequency_count: invoice.series.frequency_count,
      end_after_number_invoices: invoice.series.end_after_number_invoices,
      end_at: invoice.series.end_at,
      issued_days_before_due_date: invoice.series.issued_days_before_due_date,
      notify_days_before_due_date: invoice.series.notify_days_before_due_date,
    }
  }

  return {
    is_sync:
      // always sync
      invoice.sync_mode === "always" ||
      // recurring data has changed and the user agrees to sync
      (invoice.sync_mode === "ask" && invoice?.has_sync_data_changed && invoice?.should_sync_data),
    invoice: {
      id: id || undefined,
      account_customer_id: customers?.[0]?.id ?? null,
      // if subTotal is 0, don't send the pricemodifier
      account_pricemodifier_id: subTotal === 0 ? undefined : account_pricemodifier_id || undefined,
      pricemodifier_name: subTotal === 0 ? undefined : pricemodifier_name || undefined,
      pricemodifier_value_flat: subTotal === 0 ? undefined : pricemodifier_value_flat || undefined,
      pricemodifier_value_pct: subTotal === 0 ? undefined : pricemodifier_value_pct || undefined,
      statement_period_start,
      statement_period_end,
      expected_issue_at,
      expected_notify_at,
      due_at,
      recipient_message,
      internal_note,
      is_recipient_message_recurring, // shouldn't take effect for invoices that has been issued
      is_pricemodifier_recurring, // shouldn't take effect for invoices that has been issued
      is_send_via_email,
      is_send_via_sms,
      card_surcharge_to,
      email_cc_recipients: email_cc_recipients.filter((email) =>
        email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/),
      ),

      items: (items || []).map((item) => {
        const {
          id,
          account_customer_category_id,
          /* eslint-disable-next-line  */
          account_customer_category_name,
          account_priceitem_id,
          name,
          price,
          description,
          account_pricemodifier_id,
          pricemodifiers = [],
          quantity_measurement,
          quantity,
          is_recurring,
        } = item

        return {
          id: id || undefined,
          account_customer_category_id:
            account_customer_category_id === INDIVIDUAL_ITEMS ? null : account_customer_category_id,
          account_priceitem_id,
          name,
          price,
          description,
          account_pricemodifier_id,
          pricemodifiers: pricemodifiers.map((pricemodifier) => {
            return {
              id: pricemodifier.id,
              name: pricemodifier.name,
              value_pct:
                typeof pricemodifier.value_pct === "string"
                  ? +pricemodifier.value_pct
                  : pricemodifier.value_pct || null,
              value_flat:
                typeof pricemodifier.value_flat === "string"
                  ? +pricemodifier.value_flat
                  : pricemodifier.value_flat || null,
              is_tax: pricemodifier.is_tax,
              account_pricemodifier_id: pricemodifier.account_pricemodifier_id,
            }
          }),
          quantity_measurement,
          quantity: +quantity,
          is_recurring,
        }
      }),
      series,
    },
  }
}
