import { DateTime } from "luxon"
import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { logoutThunk } from "@/features/auth/auth-queries"
import type { Invoice, InvoiceCustomer } from "@/types"
import { InvoiceStatus, SeriesFrequency } from "@/types"
import { HYDRATE } from "next-redux-wrapper"
import { RootState } from "@/config/store"
import assignIn from "lodash/assignIn"
import { reorderItems } from "../builder/utils"

export const defaultSeries = {
  frequency_count: 1,
  frequency: SeriesFrequency.MONTHLY,
  issued_days_before_due_date: 0,
  notify_days_before_due_date: null,
  end_at: null,
  end_after_number_invoices: null,
}

const hydrate = createAction<RootState>(HYDRATE)

// parse invoice from server into a redux state
export const parseInvoiceResponseToInvoiceState = (invoice) => {
  const status = invoice.status || InvoiceStatus.DRAFT
  const is_recurring = !!invoice?.series

  const sync_mode = invoice?.sync_mode || "always"
  // invoice in creation mode: always
  // status === DRAFT: always
  // status === SCHEDULED (1st invoice, i.e. series.status === NOT_STARTED): always
  // status === SCHEDULED (2nd or later invoice, i.e. series.status !== NOT_STARTED): ask
  // status === ISSUED, PROCESSING, CANCELED, DELETED, COMPLETED: never

  let customers: InvoiceCustomer[] = []

  // If we receive
  if (Object.keys(invoice?.customer ?? {}).length) {
    customers = [invoice?.customer]
  } else if (Array.isArray(invoice?.customers)) {
    customers = invoice?.customers
  } else if (invoice?.customer === null) {
    delete invoice?.customer
  }

  const invoiceToReturn: Invoice = {
    ...invoice,
    customers,
    items: reorderItems(invoice.items),
    status,
    // general data that has changed, used for autosave
    has_changed: false,
    // only recurring data that are syncable, like item, discount/surcharge, recurring message
    has_sync_data_changed: false,
    should_sync_data: false,
    sync_mode,
    // use to force save settings that should be saved right away even though sync_mode = 'ask'
    force_save: false,

    is_recurring,
    is_recipient_message_recurring:
      !!invoice?.series?.recipient_message && invoice?.series?.recipient_message === invoice?.recipient_message,
    is_pricemodifier_recurring:
      !!invoice?.series?.pricemodifier_name &&
      invoice?.series?.pricemodifier_name === invoice?.pricemodifier_name &&
      invoice?.series?.pricemodifier_value_pct === invoice?.pricemodifier_value_pct &&
      invoice?.series?.pricemodifier_value_flat === invoice?.pricemodifier_value_flat,
    use_statement_period: !!(invoice?.statement_period_start || invoice?.statement_period_end),
    // Hide recurring switch for non draft and scheduled invoices
    is_recurring_modifiable: [InvoiceStatus.DRAFT, InvoiceStatus.SCHEDULED].includes(status),
    is_series_modifiable: sync_mode === "always",
  }

  delete invoiceToReturn.customer

  return invoiceToReturn
}

const now = DateTime.now().startOf("day").toISODate()

const emptyInvoice = {
  account_pricemodifier_id: null,
  pricemodifier_name: null,
  pricemodifier_value_flat: null,
  pricemodifier_value_pct: null,
  status: InvoiceStatus.DRAFT,
  recipient_message: "",
  due_at: now,
  expected_issue_at: now,
  expected_notify_at: now,
  statement_period_start: null,
  statement_period_end: null,
  series: null,
  items: [],
  customers: [],
  is_send_via_email: true,
  is_send_via_sms: true,
  email_cc_recipients: [],
  taxes: [],
  force_save: false,
  is_readonly: false,
  has_changed: false,
}

export interface InvoiceState {
  invoice: Invoice
  invoicePreview: Invoice
}

const initialState: InvoiceState = {
  invoice: parseInvoiceResponseToInvoiceState(emptyInvoice),
  invoicePreview: parseInvoiceResponseToInvoiceState(emptyInvoice),
}

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    // @ts-ignore
    setInvoice: (state, action: PayloadAction<Invoice>) => {
      return {
        ...state,
        invoice: {
          ...action.payload,
          ...(action?.payload?.customer ? { customers: [action.payload.customer] } : {}),
          items: reorderItems(action.payload.items || []),
        },
      }
    },
    refetchInvoice: (_, action: PayloadAction<Invoice | {} | null>) => {
      return {
        invoice: parseInvoiceResponseToInvoiceState(action?.payload ?? {}),
        invoicePreview: parseInvoiceResponseToInvoiceState(action?.payload ?? {}),
      }
    },

    setInvoiceCustomers: (state, action: PayloadAction<InvoiceCustomer[]>) => {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          customers: [...action.payload],
        },
        invoicePreview: {
          ...state.invoicePreview,
          customers: [...action.payload],
        },
      }
    },
    resetInvoice: (_, action: PayloadAction<undefined | Partial<Invoice>>) => {
      return {
        invoice: {
          ...initialState.invoice,
          ...(typeof action?.payload === "object" ? action.payload : {}),
        },
        invoicePreview: {
          ...initialState.invoicePreview,
          ...(typeof action?.payload === "object" ? action.payload : {}),
        },
      }
    },
    setInvoiceRecurring: (state, action: PayloadAction<boolean>) => {
      const is_recurring = action.payload

      const p = {
        is_recurring,
        is_recipient_message_recurring: is_recurring,
        is_pricemodifier_recurring: is_recurring,
      }

      const invoice = state.invoice

      return {
        ...state,
        invoice: {
          ...invoice,
          ...p,
          expected_issue_at: invoice?.due_at,
          expected_notify_at: invoice?.expected_notify_at ? invoice?.due_at : null,
          ...{
            series: is_recurring
              ? {
                  ...defaultSeries,
                  issued_days_before_due_date: 0,
                  notify_days_before_due_date: invoice?.expected_notify_at ? 0 : null,
                }
              : null,
          },
          customers: invoice.customers || [],
          items: (invoice.items || []).map((i) => ({ ...i, is_recurring })),
          has_changed: true,
        },
      }
    },
    duplicateInvoice: (state, action: PayloadAction<Invoice>) => {
      const data = typeof action?.payload === "object" ? action.payload : {}

      const invoice = parseInvoiceResponseToInvoiceState({
        ...data,
        sync_mode: "always",
      })

      // default value of recurring
      const series = invoice?.series ?? undefined

      const isSeries = !!series?.id

      const updatedInvoice = {
        ...invoice,
        id: "",
        account_customer_id: "",
        allowable_actions: {} as any,
        status: InvoiceStatus.DRAFT,
        is_recurring: isSeries,
        is_recipient_message_recurring: isSeries,
        is_pricemodifier_recurring: isSeries,
        is_recurring_modifiable: true,
        is_series_modifiable: true,
        customers: [],
        items: (invoice.items || []).map((i) => ({
          ...i,
          id: undefined,
          account_customer_category_id: null,
          account_customer_category_name: null,
          account_customer_category: null,
          account_pricemodifier_id: null,
          is_recurring: isSeries,
        })),
        created_at: null,
        updated_at: null,
        is_readonly: false,
        internal_note: "",
        due_at: DateTime.now().toFormat("yyyy-MM-dd"),
        expected_issue_at: DateTime.now().toFormat("yyyy-MM-dd"),
        expected_notify_at: DateTime.now().toFormat("yyyy-MM-dd"),
      }

      if (updatedInvoice?.events) {
        delete updatedInvoice.events
      }

      updatedInvoice.series = isSeries
        ? {
            frequency_count: invoice?.series?.frequency_count ?? 1,
            frequency: invoice?.series?.frequency ?? SeriesFrequency.MONTHLY,
            issued_days_before_due_date: invoice?.series?.issued_days_before_due_date ?? 0,
            notify_days_before_due_date: invoice?.series?.notify_days_before_due_date ?? null,
            end_at: invoice?.series?.end_at ?? null,
            end_after_number_invoices: invoice?.series?.end_after_number_invoices ?? null,
          }
        : undefined

      return {
        ...state,
        invoice: updatedInvoice,
        invoicePreview: updatedInvoice,
      }
    },
    updatePaymentHistory: (state, action: PayloadAction<Invoice["payments"]>) => {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          payments: action.payload,
        },
        invoicePreview: {
          ...state.invoicePreview,
          payments: action.payload,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutThunk.fulfilled, () => initialState)
      .addCase(hydrate, (state, action) => {
        return assignIn(state, action?.payload?.auth)
      })
  },
})

export const {
  resetInvoice,
  setInvoice,
  setInvoiceCustomers,
  refetchInvoice,
  setInvoiceRecurring,
  duplicateInvoice,
  updatePaymentHistory,
} = invoiceSlice.actions

export default invoiceSlice.reducer
