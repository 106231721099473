import NiceModal, { useModal } from "@ebay/nice-modal-react"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import InlineContainer from "@/components/InlineContainer"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/CloseRounded"
import NeutralButton from "@/components/buttons/NeutralButton"
import DownloadIcon from "@mui/icons-material/DownloadRounded"
import { useRouter } from "next/router"
import { useTheme, useMediaQuery } from "@mui/material"
import { routes } from "@/config/api"
import { downloadFile } from "@/utils/download"
import useAppSelector from "@/hooks/useAppSelector"
import { selectInvoice } from "@/features/invoice-management/invoice-management-selectors"
import { DateTime } from "luxon"
import AffirmativeButton from "@/components/buttons/AffirmativeButton"
import InvoiceQuotePreview from "./InvoiceQuotePreview"
import { selectQuote } from "../quote-management/quote-management-selectors"

export default NiceModal.create(
  ({
    mode,
    is_interactable,
    hideCustomerContact = false,
    sync = true,
    onExit,
  }: {
    mode: "invoice" | "quote"
    is_interactable: boolean
    hideCustomerContact?: boolean
    sync?: boolean
    onExit?: any
  }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const router = useRouter()
    // @ts-ignore
    const invoiceQuote = useAppSelector(mode === "invoice" ? selectInvoice : selectQuote)
    const modal = useModal()

    const handleCloseModal = () => {
      modal.hide()
    }

    const couldDownload = invoiceQuote?.id || (mode === "invoice" ? router?.query?.invoiceId : router?.query?.quoteId)

    return (
      <Dialog
        open={modal.visible}
        maxWidth="sm"
        fullWidth
        fullScreen={isMobile}
        onClose={handleCloseModal}
        TransitionProps={{
          onExited: () => {
            if (typeof onExit === "function") {
              onExit()
            }
            modal.remove()
          },
        }}
      >
        <DialogTitle sx={{ padding: "0.75rem 1.25rem" }}>
          <InlineContainer justifyContent="space-between">
            <Typography
              variant="h6"
              sx={{
                color: "primary.dark",
                paddingLeft: { xs: "30px", md: 0 },
                textAlign: { xs: "center", md: "left" },
                width: "100%",
              }}
            >
              {mode === "invoice" ? "Invoice" : "Quote"} Preview
            </Typography>
            <IconButton
              aria-label="close"
              size="small"
              onClick={handleCloseModal}
              sx={{
                color: "grey.500",
                position: "relative",
                right: -6,
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InlineContainer>
        </DialogTitle>
        <DialogContent sx={{ padding: "2rem 1.25rem" }} dividers>
          <InvoiceQuotePreview
            is_interactable={is_interactable}
            mode={mode}
            hideCustomerContact={hideCustomerContact}
            sync={sync}
          />
        </DialogContent>
        <DialogActions sx={{ padding: "0.75rem 1.25rem" }}>
          <InlineContainer justifyContent={couldDownload ? "space-between" : "center"} width="100%">
            <NeutralButton
              disableElevation
              onClick={() => {
                modal.reject()
                handleCloseModal()
              }}
            >
              Close
            </NeutralButton>
            {couldDownload && (
              <AffirmativeButton
                size="small"
                startIcon={<DownloadIcon fontSize="small" />}
                onClick={async () => {
                  if (mode === "invoice") {
                    // @ts-ignore
                    const formattedDueDate = invoiceQuote.due_at
                      ? DateTime.fromFormat(invoiceQuote.due_at, "yyyy-MM-dd").toFormat("MM-dd-yyyy")
                      : ""
                    await downloadFile(
                      routes.invoice.pdf((router?.query?.invoiceId || invoiceQuote?.id) as string),
                      `Finli-${invoiceQuote?.account?.public_name}-${formattedDueDate}.pdf`,
                    )
                  } else {
                    await downloadFile(
                      routes.quote.pdf((router?.query?.quoteId || invoiceQuote?.id) as string),
                      `${invoiceQuote?.account?.public_name}-Quote-${Math.floor(DateTime.now().toSeconds())}.pdf`,
                    )
                  }
                }}
              >
                Download .pdf
              </AffirmativeButton>
            )}
          </InlineContainer>
        </DialogActions>
      </Dialog>
    )
  },
)
