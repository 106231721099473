import { initApi, routes } from "@/config/api"
import type { Account, Analytics, User } from "@/types"
import { GetServerSidePropsContext } from "next"

export const switchAccount = async (accountId: string) => {
  return await initApi()
    .post(routes.user.context.switch, { accountId })
    .then(({ data }) => {
      return data
    })
}

export const createNewAccount = async (partnershipId: string | null) =>
  await initApi()
    .post(routes.account.create, { partnershipId })
    .then(({ data }) => data)

export const saveUserSettings = async (values: Partial<User>) =>
  await initApi()
    .patch<User>(routes.user.base, values)
    .then(({ data }) => data)

export const fetchAccountDetails = async (req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.account.base)
    .then(({ data }) => data?.data)

export const saveAccountDetails = async (values: Partial<Account>) => {
  return await initApi()
    .patch(routes.account.base, values)
    .then(({ data }) => data?.data)
}

export const fetchTeamMembers = async (req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.account.users)
    .then(({ data }) => data?.data)

export const inviteTeamMember = async (values: Partial<User>) =>
  await initApi()
    .post(routes.role.create, values)
    .then(({ data }) => data?.data)

export const resendInvitation = async (roleId: string) =>
  await initApi()
    .post(routes.role.resendNotification(roleId))
    .then(({ data }) => data?.data)

export const deleteInvitation = async (roleId: string) =>
  await initApi()
    .delete(routes.role.delete(roleId))
    .then(({ data }) => data?.data)

export const acceptInvitation = async (values: {
  code: string
  password: string
  role_id: string
  account_customer_id: string
  email: string
  cell_phone: string
  sign_up_with?: "EMAIL" | "PHONE"
}) =>
  await initApi().post(routes.user.reset.password, {
    code: values?.code,
    password: values?.password,
    sign_up_with: values?.sign_up_with ?? undefined,
    role_id: values?.role_id,
  })

export const fetchAccountAnalytics = async (filters?: any, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get<{ data: Analytics }>(routes.account.analytics(filters))
    .then(({ data }) => data?.data)

export const fetchAccountActivities = async (filters?: any, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get<{ data: Analytics["events"] }>(routes.account.activities(filters))
    .then(({ data }) => data?.data)

export type PaymentsOnDate = {
  payer_name: string
  amount: number
}

export const createIntegration = async ({ integration, ...params }) => {
  return await initApi()
    .post(routes.account.integration.create, {
      type: integration,
      data: params,
    })
    .then(({ data }) => data)
}

export const validateSubdomain = async (subdomain: string, req?: GetServerSidePropsContext["req"]) =>
  await initApi(req)
    .get(routes.account.subdomain(subdomain))
    .then(() => 204)
    .catch((error) => {
      return error?.response?.data?.message ?? "Unexpected error"
    })

export const getPblAccount = async (subdomain: string, req?: GetServerSidePropsContext["req"]) => {
  return await initApi(req)
    .get(routes.account.pbl(subdomain))
    .then(({ data }) => data?.data)
}

export const createSubscription = async ({
  frequency,
  walletInstrumentId,
}: { frequency?: string; walletInstrumentId?: string } = {}) => {
  return await initApi()
    .post(routes.account.subscription.create, {
      type: frequency,
      wallet_instrument_id: walletInstrumentId,
    })
    .then(({ data }) => data?.data)
}

export const verifyUserInquiry = async (inquiryId: string) => {
  return await initApi()
    .post(routes.account.verifyInquiry, {
      inquiryId,
    })
    .then(({ data }) => data?.data)
}
