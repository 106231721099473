import CardContent from "@mui/material/CardContent"
import NeutralButton from "@/components/buttons/NeutralButton"
import PlusIcon from "@mui/icons-material/AddRounded"
import Typography from "@mui/material/Typography"
import InlineContainer from "@/components/InlineContainer"
import { Pagination } from "@mui/material"
import { useState } from "react"
import Box from "@mui/material/Box"
import TestId from "@/constants/testIds"
import { InvoiceCustomer } from "@/types"
import FormattedPhone from "@/components/FormattedPhone"
import EmailIcon from "@mui/icons-material/MailOutlineRounded"
import PhoneIcon from "@mui/icons-material/PhoneIphoneRounded"
import { Card, Collapse } from "@mui/material"
import ExpandIcon from "@mui/icons-material/ExpandMoreRounded"
import IconButton from "@mui/material/IconButton"

export const CustomerEmail = ({ email }) =>
  !email ? null : (
    <InlineContainer>
      <EmailIcon fontSize="small" sx={{ color: "grey.700", marginRight: 1 }} />
      <Typography variant="body2" sx={{ color: "grey.600" }}>
        {email}
      </Typography>
    </InlineContainer>
  )

export const CustomerCellPhone = ({ cell_phone }) =>
  !cell_phone ? null : (
    <InlineContainer>
      <PhoneIcon fontSize="small" sx={{ color: "grey.700", marginRight: 1 }} />
      <Typography variant="body2" sx={{ color: "grey.600" }}>
        <FormattedPhone value={cell_phone} />
      </Typography>
    </InlineContainer>
  )

const CustomerCardRow = ({
  handleRemoveCustomer,
  customer,
  collapsible,
  removable,
}: {
  handleRemoveCustomer: any
  customer: InvoiceCustomer
  collapsible: boolean
  removable: boolean
}) => {
  const Coll = collapsible ? Collapse : Box
  const [showCustomer, setShowCustomer] = useState(false)

  return (
    <Card
      elevation={0}
      sx={{
        textAlign: "center",
        border: "1px solid",
        borderColor: "grey.300",
        paddingBottom: "0.75rem !important",
        paddingLeft: "1rem",
        paddingTop: "0.75rem",
        marginBottom: "0.35rem",
      }}
    >
      <InlineContainer
        onClick={() => {
          setShowCustomer(!showCustomer)
        }}
        justifyContent="space-between"
        sx={{
          width: { xs: "95%", md: "97%" },
          cursor: collapsible ? "pointer" : "auto",
        }}
      >
        <Typography variant="h6">{customer?.name}</Typography>
        {collapsible && (
          <IconButton size="small" sx={{ margin: "0 -0.5rem 0 0.25rem", padding: 0 }}>
            <ExpandIcon
              fontSize="medium"
              sx={{
                color: "common.black",
                transform: `rotate(${showCustomer ? 180 : 0}deg)`,
                transition: "transform 200ms",
              }}
            />
          </IconButton>
        )}
      </InlineContainer>
      <Box textAlign="left">
        <Coll in={showCustomer}>
          <CustomerEmail email={customer?.email} />
          <CustomerCellPhone cell_phone={customer?.cell_phone} />
          {removable && (
            <Box sx={{ ":hover": { cursor: "pointer" }, width: "fit-content" }}>
              <Typography
                variant="body2"
                fontWeight={600}
                color="error"
                onClick={() => handleRemoveCustomer(customer)}
                sx={{
                  marginTop: "0.75rem",
                }}
              >
                Remove
              </Typography>
            </Box>
          )}
        </Coll>
      </Box>
    </Card>
  )
}

const CustomerCard = ({
  customers,
  handleAddCustomer,
  handleRemoveCustomer,
  is_readonly,
  removable,
  disableAdd,
}: {
  customers: InvoiceCustomer[]
  handleAddCustomer: any
  handleRemoveCustomer: any
  is_readonly: boolean
  removable: boolean
  disableAdd: boolean
}) => {
  const [page, setPage] = useState(1)
  const slicedCustomers = customers?.slice((page - 1) * 10, page * 10)

  return (
    <>
      {customers?.length > 1 && (
        <InlineContainer
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="column"
          sx={{
            backgroundColor: "grey.600",
            color: "white",
            padding: "0.5rem 1rem",
            marginBottom: "0.5rem",
          }}
        >
          <Typography variant="h6">Batch Invoice Recipients</Typography>
          <Typography variant="body1">
            When creating a Batch Invoice, each customer is billed <strong>separately</strong>.
          </Typography>
        </InlineContainer>
      )}

      <Card
        elevation={0}
        sx={{
          background: customers?.length ? "transparent" : "common.white",
          border: customers?.length ? "none" : "1px solid",
          borderColor: "grey.300",
          marginBottom: customers?.length ? (is_readonly ? "1rem" : "0") : "1.65rem",
        }}
      >
        {slicedCustomers?.map((customer) =>
          is_readonly && !customers?.length ? null : (
            <CustomerCardRow
              key={customer?.id}
              customer={customer}
              handleRemoveCustomer={handleRemoveCustomer}
              collapsible={customers?.length > 1}
              removable={removable}
            />
          ),
        )}
        {!is_readonly && (
          <CardContent
            sx={{
              display: customers?.length >= 10 ? "flex" : "",
              textAlign: "center",
              paddingBottom: "1rem !important",
              justifyContent: "space-between",
            }}
          >
            {customers?.length < 1 && (
              <NeutralButton
                startIcon={<PlusIcon fontSize="small" />}
                onClick={handleAddCustomer}
                size="small"
                disabled={disableAdd}
                data-testid={TestId.Invoice.AddCustomer}
              >
                {`Add ${customers?.length > 0 ? "More" : "Customers"}`}
              </NeutralButton>
            )}
            {customers?.length >= 10 && (
              <Typography variant="body2">
                Showing {slicedCustomers?.length} of {customers?.length} customers
              </Typography>
            )}
          </CardContent>
        )}
        {customers?.length >= 10 && (
          <Box display="flex" justifyContent="flex-end">
            <Pagination
              variant="outlined"
              count={Math.ceil(customers?.length / 10)}
              page={page}
              onChange={(_, page) => setPage(page)}
              sx={{ marginBottom: "0.5rem" }}
            />
          </Box>
        )}
      </Card>
    </>
  )
}

export default CustomerCard
